<template>
  <!-- 1.创建地图容器元素 -->
  <div>
    <div class="celestial_map" id="mapDiv"></div>
    <div v-if="ifShowPop" class="pop_style">
      <img src="../../assets/img/cancel.png" class="cancel_sty" @click="closePop" alt="">
      <div class="img_box">
        <el-carousel trigger="click" height="800px" v-if="reportImg.length>0" arrow="always">
          <el-carousel-item v-for="(item,index) in reportImg" :key="index">
            <img :src="item | imgOnline" class="img_item" alt="" >
          </el-carousel-item>
        </el-carousel>
        <div style="font-size: 35px;text-align: center;line-height: 600px;" v-else>
          暂无农检报告图片
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import {homepageMap} from '@/utils/api/index.js'

  export default {
    name: "cityMap",
    data() {
      return {
        imageURL: 'https://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=f5c252c1d8ffa5a01d3c3e74c15f4291',
        centerList: [], //中心点,新罗区的经纬度
        markerArrs: [],
        line: null,
        ifShowPop: false,
        reportImg: [],
      }
    },

    created() {
      this.homepageMap()
      // this.getMapDetail()
    },
    methods: {
      closePop() {
        this.ifShowPop = false
      },
      // async getMapDetail(){
      //   let res = await  getMapDetail({
      //     shopId:this.$route.query.shopId,
      //     // shopId:'USER-202211000005'
      //   })
      //   this.centerList =res.data.mapData[0].dot
      //   this.markerArrs = res.data.mapData
      //   console.log(this.markerArrs)
      // },
      async homepageMap() {
        let res = await homepageMap()
        if(!this.$route.query.shopId){
          this.centerList = res.data.center
          this.markerArrs = res.data.mapMap
        }


        let T = window.T;
        let lay = new T.TileLayer(this.imageURL, {minZoom: 1, maxZoom: 18});
        let config = {layers: [lay]};

        this.map = new T.Map('mapDiv', config); // 地图实例
        this.map.centerAndZoom(new T.LngLat(this.centerList[0], this.centerList[1]), 15); //设置中心点
        this.map.enableScrollWheelZoom();//允许鼠标双击放大地图

        this.markerArrs.forEach(item => {
          let marker = new T.Marker(new T.LngLat(item.dot[0], item.dot[1]));//创建标注对象
          this.map.addOverLay(marker); //向地图上添加标注
          this.addwindowclick(marker, item) //点击事件出弹框
          let points = [];
          item.list.map(val => {
            points.push(new T.LngLat(val.lng, val.lat));
          })
          this.line = new T.Polyline(points, {
            color: item.color
          }); //创建线对象
          this.map.addOverLay(this.line);//向地图上添加线
        })
      },


      addwindowclick(marker, item) {
        let T = window.T;
        marker.addEventListener("click", () => {
          this.map.panTo(new T.LngLat(item.dot[0], item.dot[1]))
          let infoWin = new T.InfoWindow();
          let sContent = `<div style="margin: 0px;">
                                        <div style="font-size: 20px;font-weight: bold">${item.name}</div>
                                        <div style="font-size: 20px;font-weight: 500">联系电话: ${item.phone}</div>
                                        <div style="font-size: 20px;font-weight: 500">位置:${item.address}</div>
                                        <div style="font-size: 20px;font-weight: 500">种植品种: ${item.type}</div>
                                        <div style="font-size: 20px;font-weight: 500">面积: ${item.area}</div>

                                        <div style="font-size: 20px;font-weight: 500">农药用量: ${item.pesticideDosag}</div>
                                        <div style="font-size: 20px;font-weight: 500">化肥用量:${item.fertilizerConsumption}</div>
                                        <div style="font-size: 20px;font-weight: 500">有机肥用量: ${item.organicFertilizerConsumption}</div>
                                        <div style="font-size: 20px;font-weight: 500;display: flex;flex-direction: row">农检报告:
                                        <div style="font-size: 20px;font-weight: 500;color: #3a8ee6" class="checkReport"   >查看报告</div>
                                        </div>
                                    </div>`
          marker.openInfoWindow(infoWin.setContent(sContent));

          let length = document.getElementsByClassName('checkReport').length - 1
          let checkReport = document.getElementsByClassName('checkReport')[length]
          checkReport.addEventListener("click", () => {
            this.ifShowPop = true
            this.reportImg = item.agriculturalInspectionReportPics
          })
        })
      }
    }
  }
</script>

<style scoped>
  .celestial_map {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
  }
  .pop_style {
    width: 100%;
    height: 82%;
    background: white;
    position: fixed;
    z-index: 9999999;
    margin-top: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
  }

  .cancel_sty {
    margin: 10px 0px 10px 1800px;
    width: 100px;
    height: 100px;
  }

  .img_box {
    width: 95%;
    height: 75%;
    margin: 0px auto 20px;
    background: rgba(255, 255, 255, 0.8);
  }

  .img_item {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

 ::v-deep .el-carousel__arrow {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100px;
    height: 100px;
  }
</style>
