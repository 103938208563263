<template>
  <div>
      <city-map/>
  </div>
</template>

<script>
  import cityMap from "../components/cityMap/cityMap";
export default {
  name: "anotherMap",
  components: {
    cityMap
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  },
}
</script>

<style scoped lang="">

</style>
